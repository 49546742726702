<template>
    <path class="primary" opacity="0.05" d="m255.4 140.6c0 38-30.8 68.8-68.8 68.8s-68.8-30.8-68.8-68.8 30.8-68.8 68.8-68.8 68.8 30.8 68.8 68.8z"/>
    <path class="primary" opacity="0.1" d="m314.4 178.1h-71.6s13.1-14 2.2-31.7c-12.6-20.6-3-36.3-3-36.3h72.4v68z"/>
    <path d="m305.2 64-9.7-9.7 9.7-9.7 9.7 9.7-9.7 9.7zm-6.6-9.7 6.6 6.6 6.6-6.6-6.6-6.6-6.6 6.6z"/>
    <rect class="primary" x="239.8" y="184.7" width="74.5" height="2.9"/>
    <rect class="primary" x="239.8" y="194.7" width="73.5" height="2.9"/>
    <rect class="primary" x="237.9" y="176" width="60" height="2.9"/>
    <path class="primary" d="m272.6 142.4h-11.2c-2.8 0-4-0.8-4-5.9v-0.6c0-3.5 1.3-4.8 5-4.8h14.4c0.8 0 1.4-0.6 1.4-1.4s-0.6-1.5-1.4-1.5h-7.1v-5.7h-2.9v5.7h-4.3c-5.2 0-7.9 2.6-7.9 7.8v0.6c0 4.4 0.8 8.8 6.9 8.8h11.2c3.1 0 4.8 1.7 4.8 4.5v2.6c0 3.5-2.6 4.3-4.8 4.3h-15.9c-0.8 0-1.4 0.6-1.4 1.4s0.6 1.5 1.4 1.5h10v5.7h2.9v-5.7h3c4.8 0 7.8-2.8 7.8-7.2v-2.6c-0.2-4.5-3.2-7.5-7.9-7.5z"/>
    <path class="primary" d="m291.6 144.6c0 2.2 1.8 3.9 3.9 3.9s3.9-1.8 3.9-3.9-1.8-3.9-3.9-3.9c-2.1-0.1-3.9 1.7-3.9 3.9z"/>
    <path class="primary" d="m315.4 110.2c0-0.8-0.7-1.5-1.5-1.5l-74.9-0.1 0.3 3.1 53.5-0.1c0.9 16.8 16.5 19.8 19.6 20.2v27.4c-19.1 0.5-20.8 16.6-20.8 16.8l2.9 0.3c0.1-0.6 1.5-13.6 17.9-14.1v13.8h-7.1v2.9h8.5c0.8 0 1.5-0.7 1.5-1.5l0.1-67.2zm-19.6 1.5h16.7v17.3c-3.3-0.5-15.9-3.2-16.7-17.3z"/>
    <path d="m203.3 7.4h-6.3v-6.3c0-0.6-0.5-1.1-1.1-1.1s-1.1 0.5-1.1 1.1v6.3h-6.3c-0.6 0-1.1 0.5-1.1 1.1s0.5 1.1 1.1 1.1h6.3v6.3c0 0.6 0.5 1.1 1.1 1.1s1.1-0.5 1.1-1.1v-6.3h6.3c0.6 0 1.1-0.5 1.1-1.1s-0.5-1.1-1.1-1.1z"/>
    <path d="m138.9 253.6h-6.3v-6.3c0-0.6-0.5-1.1-1.1-1.1s-1.1 0.5-1.1 1.1v6.3h-6.3c-0.6 0-1.1 0.5-1.1 1.1s0.5 1.1 1.1 1.1h6.3v6.3c0 0.6 0.5 1.1 1.1 1.1s1.1-0.5 1.1-1.1v-6.3h6.3c0.6 0 1.1-0.5 1.1-1.1 0-0.7-0.5-1.1-1.1-1.1z"/>
    <path d="m270.3 47.9c0-0.4-0.2-0.8-0.6-1.1l-1-0.8-6.9-5.9-0.5-0.4v-17.7l2.9 2.9c0.6 0.6 1.4 0.6 2.1 0 0.5-0.6 0.5-1.4-0.1-2.1l-5.4-5.4c-0.1-0.1-0.2-0.1-0.2-0.2-0.1-0.1-0.4-0.1-0.6-0.1s-0.4 0-0.6 0.1c-0.1 0-0.2 0.1-0.4 0.2 0 0-0.1 0-0.1 0.1l-5.3 5.3c-0.6 0.6-0.6 1.4 0 2.1 0.6 0.6 1.4 0.6 2.1 0l2.9-2.9v18.3c0 0.2 0 0.4 0.1 0.5v0.1c0.1 0.1 0.1 0.2 0.2 0.3 0 0.1 0.1 0.1 0.1 0.1l0.1 0.1 1 0.8 6.9 5.9 0.5 0.4v26.6c0 0.8 0.6 1.4 1.4 1.4s1.4-0.6 1.4-1.4v-27.2z"/>
    <path d="m4.4 116.5c-2.4 0-4.4-2-4.4-4.4s2-4.4 4.4-4.4 4.4 2 4.4 4.4c0 2.5-2 4.4-4.4 4.4zm0-7c-1.4 0-2.6 1.2-2.6 2.6s1.2 2.6 2.6 2.6 2.6-1.2 2.6-2.6-1.2-2.6-2.6-2.6z"/>
    <path d="m30.8 184.6c0 1.3-1 2.3-2.3 2.3s-2.3-1-2.3-2.3 1-2.3 2.3-2.3 2.3 1 2.3 2.3z"/>
    <path d="m233.8 229.9h-106.5c-0.8 0-1.5-0.7-1.5-1.5s0.7-1.5 1.5-1.5h106.6c6.7 0 12.2-5.3 12.4-12v-9.5c0-0.8 0.6-1.5 1.4-1.5s1.5 0.6 1.5 1.4v0.1 9.5c-0.1 8.2-7.1 15-15.4 15z"/>
    <path d="m240.5 89.4h-166.2v-20.3c0-6.5 6.3-12.6 12.9-12.6h5v8.2c0 4.2 3.4 7.6 7.6 7.6s7.6-3.4 7.6-7.6v-15c0-4.1-3.4-7.4-7.5-7.5-4.2 0-7.6 3.3-7.6 7.5v3.9h-5c-8.3 0-15.8 7.4-15.8 15.5v100.6c0 0.8 0.7 1.5 1.5 1.5s1.5-0.7 1.5-1.5v-35.1h51.5v48.7h-22.2-0.1c-0.8 0-1.5 0.7-1.4 1.5 0 0.8 0.7 1.5 1.5 1.4h22.2v10c0 0.8 0.7 1.5 1.5 1.5s1.5-0.7 1.5-1.5v-10h59.4v19.3c0 0.8 0.7 1.5 1.5 1.5s1.5-0.7 1.5-1.5v-19.3h8.6 0.1c0.8 0 1.5-0.7 1.4-1.5 0-0.8-0.7-1.5-1.5-1.4h-8.6v-48.7h22.2c0.8 0 1.4-0.6 1.4-1.4s-0.6-1.5-1.4-1.5h-22.2v-39.4h49.4 0.1c0.8 0 1.5-0.7 1.4-1.5-0.3-0.8-1-1.4-1.8-1.4zm-145.4-34.3v0-0.1-5.3c0-2.6 2.1-4.6 4.6-4.6 2.6 0 4.6 2.1 4.6 4.6v15c0 2.6-2.1 4.6-4.6 4.6-2.6 0-4.6-2.1-4.6-4.6v-9.6zm33.6 37.2h59.4v39.3h-59.4v-39.3zm-54.4 39.4v-39.4h51.5v39.3h-51.5zm54.4 51.6v-48.7h59.4v48.7h-59.4z"/>
    <path d="m233.8 52.8h-6.9v-3.2c0-4.2-3.4-7.6-7.6-7.6s-7.6 3.4-7.6 7.6v3.9h-14.3v-3.9c0-4.2-3.4-7.6-7.6-7.6s-7.6 3.4-7.6 7.6v3.9h-13.2-1.8v-3.9c0-4.1-3.4-7.4-7.5-7.5-4.2 0-7.6 3.3-7.6 7.5v3.9h-15v-3.9c0-4.2-3.4-7.6-7.6-7.6s-7.6 3.4-7.6 7.6v3.9h-7.1c-0.8 0-1.5 0.7-1.5 1.5s0.7 1.5 1.5 1.5h7.1v8.2c0 4.2 3.4 7.6 7.6 7.6s7.6-3.4 7.6-7.6v-8.2h15v8.2c0 4.2 3.4 7.6 7.6 7.6s7.6-3.4 7.6-7.6v-8.2h15v8.2c0 4.2 3.4 7.6 7.6 7.6s7.6-3.4 7.6-7.6v-8.2h13.6 0.7v8.2c0 4.2 3.4 7.6 7.6 7.6s7.6-3.4 7.6-7.6v-8.9h6.9c6.5 0 12.4 6 12.4 12.5v26.1c0 0.8 0.7 1.5 1.5 1.5s1.5-0.7 1.5-1.5v-26.1c-0.2-8.2-7.3-15.5-15.5-15.5zm-99.4 11.9c0 2.6-2.1 4.6-4.6 4.6s-4.6-2.1-4.6-4.6v-15c0-2.6 2.1-4.6 4.6-4.6 2.6 0 4.6 2.1 4.6 4.6v15zm30.1 0c0 2.6-2.1 4.6-4.6 4.6s-4.6-2.1-4.6-4.6v-15c0-2.6 2.1-4.6 4.6-4.6 2.6 0 4.6 2.1 4.6 4.6v15zm30.1 0c0 2.6-2.1 4.6-4.6 4.6-2.6 0-4.6-2.1-4.6-4.6v-15c0-2.6 2.1-4.6 4.6-4.6 2.6 0 4.6 2.1 4.6 4.6v15zm29.4 0c0 2.6-2.1 4.6-4.6 4.6s-4.6-2.1-4.6-4.6v-15c0-2.6 2.1-4.6 4.6-4.6 2.6 0 4.6 2.1 4.6 4.6v15z"/>
    <path d="m163.8 105.2h-15.8c-0.8 0-1.5-0.6-1.5-1.4s0.6-1.5 1.4-1.5h0.1 15.8c0.8 0 1.5 0.7 1.4 1.5 0 0.8-0.6 1.4-1.4 1.4z"/>
    <path d="m107.2 118.8h-15.8c-0.8 0-1.5-0.7-1.5-1.5s0.7-1.5 1.5-1.5h15.8c0.8 0 1.5 0.7 1.5 1.5s-0.7 1.5-1.5 1.5z"/>
    <path d="m153.1 116.7h-5c-0.8 0-1.5-0.6-1.5-1.4s0.6-1.5 1.4-1.5h0.1 5c0.8 0 1.5 0.7 1.4 1.5 0 0.7-0.7 1.4-1.4 1.4z"/>
    <path d="m95.7 105.2h-5.7c-0.8 0-1.5-0.7-1.4-1.5 0-0.8 0.6-1.4 1.4-1.4h5.7c0.8 0 1.5 0.6 1.5 1.4s-0.6 1.5-1.5 1.5c0.1 0 0.1 0 0 0z"/>
    <path d="m228.8 99.6c-0.8 0-1.5 0.7-1.4 1.5v1.5c0 0.8 0.7 1.5 1.5 1.5s1.5-0.7 1.5-1.5v-1.5-0.1c-0.1-0.8-0.8-1.5-1.6-1.4z"/>
    <path d="m228.8 174.4c-0.8 0-1.5 0.7-1.4 1.5v2.9c0 0.8 0.7 1.5 1.5 1.5s1.5-0.7 1.5-1.5v-2.9-0.1c-0.1-0.7-0.8-1.4-1.6-1.4z"/>
    <path d="m228.9 165c-0.8 0-1.5 0.6-1.5 1.4v2.9c0 0.8 0.7 1.5 1.5 1.5s1.5-0.7 1.5-1.5v-2.9c-0.1-0.8-0.7-1.4-1.5-1.4z"/>
    <path d="m228.8 184c-0.8 0-1.5 0.7-1.4 1.5v2.9c0 0.8 0.7 1.5 1.5 1.5s1.5-0.7 1.5-1.5v-2.9-0.1c-0.1-0.8-0.8-1.4-1.6-1.4z"/>
    <path d="m228.8 193.5c-0.8 0-1.5 0.7-1.4 1.5v2.9c0 0.8 0.7 1.5 1.5 1.5s1.5-0.7 1.5-1.5v-2.9-0.1c-0.1-0.8-0.8-1.4-1.6-1.4z"/>
    <path d="m228.8 155.3c-0.8 0-1.5 0.7-1.4 1.5v2.9c0 0.8 0.7 1.5 1.5 1.5s1.5-0.7 1.5-1.5v-2.9-0.1c-0.1-0.7-0.8-1.4-1.6-1.4z"/>
    <path d="m228.9 117.3c-0.8 0-1.5 0.6-1.5 1.4v2.9c0 0.8 0.7 1.5 1.5 1.5s1.5-0.7 1.5-1.5v-2.9c-0.1-0.8-0.7-1.4-1.5-1.4z"/>
    <path d="m228.8 126.7c-0.8 0-1.5 0.7-1.4 1.5v2.9c0 0.8 0.7 1.5 1.5 1.5s1.5-0.7 1.5-1.5v-2.9-0.1c-0.1-0.8-0.8-1.4-1.6-1.4z"/>
    <path d="m228.9 107.7c-0.8 0-1.5 0.6-1.5 1.4v2.9c0 0.8 0.7 1.5 1.5 1.5s1.5-0.7 1.5-1.5v-2.9c-0.1-0.7-0.7-1.4-1.5-1.4z"/>
    <path d="m228.9 136.3c-0.8 0-1.5 0.6-1.5 1.4v2.9c0 0.8 0.7 1.5 1.5 1.5s1.5-0.7 1.5-1.5v-2.9c-0.1-0.7-0.7-1.3-1.5-1.4z"/>
    <path d="m228.9 145.9c-0.8 0-1.5 0.6-1.5 1.4v2.9c0 0.8 0.7 1.5 1.5 1.5s1.5-0.7 1.5-1.5v-2.9c-0.1-0.8-0.7-1.4-1.5-1.4z"/>
    <path d="m228.9 203.1c-0.8 0-1.5 0.6-1.5 1.4v1.5c0 0.8 0.7 1.5 1.5 1.5s1.5-0.7 1.5-1.5v-1.5c-0.1-0.7-0.7-1.3-1.5-1.4z"/>
    <path d="m39.1 155.4c-0.8 0-1.5-0.7-1.5-1.5v-31.5c0-0.8 0.6-1.5 1.4-1.5s1.5 0.6 1.5 1.4v0.1 31.5c0.1 0.8-0.6 1.5-1.4 1.5z"/>
    <path class="primary" d="m76 249.9c-16.6 0-30.1-13.5-30.1-30.1s13.5-30.1 30.1-30.1 30.1 13.5 30.1 30.1c-0.1 16.6-13.5 30.1-30.1 30.1zm0-57.2c-15 0-27.1 12.1-27.1 27.1s12.1 27.1 27.1 27.1 27.1-12.1 27.1-27.1c0-14.9-12.1-27.1-27.1-27.1z"/>
    <path class="primary" d="m75.1 228.6-13.6-9.8c-0.7-0.5-0.8-1.4-0.3-2.1s1.4-0.8 2.1-0.3l11.4 8.2 13.6-17.2c0.5-0.7 1.4-0.8 2.1-0.4 0.7 0.5 0.8 1.4 0.4 2.1l-0.1 0.1-15.6 19.4z"/>
    <path class="primary" d="m85 186.9h-20.1c-0.8 0-1.5-0.7-1.5-1.5v-6.4c0-0.8 0.7-1.5 1.5-1.5h20.1c0.8 0 1.5 0.7 1.5 1.5v6.5c-0.1 0.8-0.7 1.4-1.5 1.4zm-18.6-2.9h17.1v-3.5h-17.1v3.5z"/>
    <rect class="primary" opacity="0.1" x="126.3" y="133.3" width="61.6" height="52.6"/>
    <path class="primary" d="m191.8 186.9h-66.5v-55.7h66.5v55.7zm-62.6-4h58.5v-47.7h-58.5v47.7z"/>
    <path class="primary" d="m157.8 167.1c-0.4 0-0.7-0.1-1-0.4l-11.5-11c-0.6-0.6-0.6-1.5-0.1-2.1s1.5-0.6 2.1-0.1l10.3 9.8 13.4-17.2c0.5-0.6 1.4-0.7 2.1-0.2 0.6 0.5 0.7 1.4 0.2 2l-14.3 18.7c-0.3 0.3-0.6 0.5-1.1 0.6 0-0.1 0-0.1-0.1-0.1z"/>
    <path d="m333.8 98.5-5.4-5.4c-0.1-0.1-0.2-0.1-0.2-0.2-0.1-0.1-0.4-0.1-0.6-0.1s-0.4 0-0.6 0.1c-0.1 0-0.2 0.1-0.4 0.2 0 0-0.1 0-0.1 0.1l-5.3 5.3c-0.6 0.6-0.6 1.4 0 2.1 0.6 0.6 1.4 0.6 2.1 0l2.9-2.9v33.3c0 0.8 0.6 1.4 1.4 1.4s1.4-0.6 1.4-1.4v-33.3l2.9 2.9c0.6 0.6 1.4 0.6 2.1 0 0.4-0.6 0.4-1.5-0.2-2.1z"/>
    <path d="m57.4 41.7-5.4-5.4c-0.1-0.1-0.2-0.1-0.2-0.2-0.1-0.1-0.4-0.1-0.6-0.1s-0.4 0-0.6 0.1c-0.1 0-0.2 0.1-0.4 0.2 0 0-0.1 0-0.1 0.1l-5.3 5.3c-0.6 0.6-0.6 1.4 0 2.1 0.6 0.6 1.4 0.6 2.1 0l2.9-2.9v75.1c0 0.8 0.6 1.4 1.4 1.4s1.4-0.6 1.4-1.4v-75.1l2.9 2.9c0.6 0.6 1.4 0.6 2.1 0 0.4-0.6 0.4-1.5-0.2-2.1z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 334.2, height: 263.2 });
    },
    emits: ['dimensions'],
};
</script>
